import React from "react"

const FilterButton = props => {
  return (
    <button
      {...props}
      className="btn w-100 FilterButton text-uppercase mt-1 mb-1"
    >
      {props.children}
    </button>
  )
}

export default FilterButton
