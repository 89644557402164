import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import BlogPostItem from "../components/blogPostItem"
import { ListGroup, Row, Col } from "react-bootstrap"
import FilterButton from "../components/filterButton"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

const SortedReview = ({ data }) => {
  const posts =
    data?.allWpPost?.["posts"].map(elem => {
      return {
        ...elem,
        parsedReview: JSON.parse(elem["post"]["review"]),
      }
    }) || []

  const [sortOrder, setSortOrder] = useState({
    overallScore: false,
    permiabilityScore: false,
    pef1: false,
    pef2: false,
  })

  const [sortedPosts, setSortedPosts] = useState(posts)
  const [activePosts, setActivePosts] = useState(posts)

  const handleReviewSortClick = useCallback(
    reviewType => {
      let sortedData = []

      switch (reviewType) {
        case "overallScore":
          sortedData = sortedPosts.sort((a, b) => {
            return (
              a["parsedReview"]["review_overall_score"] -
              b["parsedReview"]["review_overall_score"]
            )
          })
          if (sortOrder[reviewType] === false) {
            setSortedPosts(sortedData)
          } else {
            setSortedPosts(sortedData.reverse())
          }

          setSortOrder(sortOrder => {
            return {
              ...sortOrder,
              overallScore: sortOrder[reviewType] ? false : true,
            }
          })
          break
        case "permiabilityScore":
          sortedData = sortedPosts.sort((a, b) => {
            return (
              a["parsedReview"]["review_scores"][2] -
              b["parsedReview"]["review_scores"][2]
            )
          })
          if (sortOrder[reviewType] === false) {
            setSortedPosts(sortedData)
          } else {
            setSortedPosts(sortedData.reverse())
          }

          setSortOrder(sortOrder => {
            return {
              ...sortOrder,
              permiabilityScore: sortOrder[reviewType] ? false : true,
            }
          })
          break
        case "pef1":
          sortedData = sortedPosts.sort((a, b) => {
            return (
              a["parsedReview"]["review_scores"][0] -
              b["parsedReview"]["review_scores"][0]
            )
          })
          if (sortOrder[reviewType] === false) {
            setSortedPosts(sortedData)
          } else {
            setSortedPosts(sortedData.reverse())
          }

          setSortOrder(sortOrder => {
            return {
              ...sortOrder,
              pef1: sortOrder[reviewType] ? false : true,
            }
          })
          break
        case "pef2":
          sortedData = sortedPosts.sort((a, b) => {
            return (
              Number.parseFloat(a["parsedReview"]["review_scores"][1]) -
              Number.parseFloat(b["parsedReview"]["review_scores"][1])
            )
          })
          if (sortOrder[reviewType] === false) {
            setSortedPosts(sortedData)
          } else {
            setSortedPosts(sortedData.reverse())
          }

          setSortOrder(sortOrder => {
            return {
              ...sortOrder,
              pef2: sortOrder[reviewType] ? false : true,
            }
          })
          break

        default:
          break
      }
    },
    [sortOrder, sortedPosts]
  )

  useEffect(() => {
    if (sortedPosts.length > 0) {
      setActivePosts(sortedPosts)
    }
  }, [sortedPosts])

  return (
    <Layout>
      <Row className="mt-3">
        <Col className="text-center" sm={6} md={3}>
          <FilterButton onClick={() => handleReviewSortClick("overallScore")}>
            FilterStudy Score
          </FilterButton>
        </Col>
        <Col className="text-center" sm={6} md={3}>
          <FilterButton
            onClick={() => handleReviewSortClick("permiabilityScore")}
          >
            Permiability Score
          </FilterButton>
        </Col>
        <Col className="text-center" sm={6} md={3}>
          <FilterButton onClick={() => handleReviewSortClick("pef1")}>
            PEF1 Score
          </FilterButton>
        </Col>
        <Col className="text-center" sm={6} md={3}>
          <FilterButton onClick={() => handleReviewSortClick("pef2")}>
            PEF2 Score
          </FilterButton>
        </Col>
      </Row>
      {activePosts.length > 0 ? (
        <div variant="flush" className="posts py-6">
          {activePosts.map(postObj => (
            <BlogPostItem
              {...postObj?.["post"]}
              images={data.allFile.images}
              key={uuidv4()}
              variant={"primary"}
            />
          ))}
        </div>
      ) : null}
    </Layout>
  )
}

export default SortedReview

export const pageQuery = graphql`
  query GetAllWPPostsWithReview {
    allWpPost(filter: { review: { ne: "[]" } }) {
      posts: edges {
        post: node {
          id
          review
          title
          uri
          featuredImageId
          databaseId
          excerpt
        }
      }
    }

    allFile {
      images: edges {
        node {
          id
          url
          childImageSharp {
            fixed(grayscale: true) {
              base64
              tracedSVG
              aspectRatio
              srcWebp
              srcSetWebp
              originalName
            }
            gatsbyImageData
          }
        }
      }
    }
  }
`
